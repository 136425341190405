.customShadow{
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

/***user tabe***/
.userTable{
    @apply bg-white 
}
.userTable th{
    @apply border text-base font-medium
}

.userTable td{
    @apply border text-base text-center
}

/*scroolbar hidden ***/
.scrollbar-none::-webkit-scrollbar{
    display: none;
}

@screen lg{

    .gridcol {
        display: grid;
        grid-template-columns: 280px 1fr ;
      }
}